import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const NewsDetailBanner = () => {
  return (
    <div className="news-detail-banner">
      <h2>News & Events</h2>
    </div>
  )
}

export default NewsDetailBanner
