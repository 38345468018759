import React from "react"
import "../../../components/layout/cssberhasil"
import Navbar from "../../../components/layout/NavbarSub"
import Header from "../../../components/layout/HeaderSub"
import Footer from "../../../components/layout/Footer"
import Mobile from "../../../components/content/Mobile"
import SEO from "../../../components/SEO"
import NewsDetailBanner from "../../../components/content/News/NewsDetailBanner"
import NewsArticle from "../../../components/content/News/NewsArticle"
import UpdatedNews from "../../../components/content/Home/UpdatedNews"
import Banner from "../../../components/content/AboutUs/BannerCareers"

import Helmet from "react-helmet"
import JSberhasil from "../../../components/layout/jsberhasil"
import { graphql } from "gatsby"
import { Row, Col } from "reactstrap"

const JobApplicationDetail = (data) => {
  const job = data.data.contentfulJobs
  const cp = data.data.contentfulContactPerson

  return (
    <React.Fragment>
      <Helmet
        bodyAttributes={{
          class:
            "home page-template-default page page-id-4074 theme-avante woocommerce-no-js menu-transparent lightbox-black leftalign footer-reveal elementor-default elementor-page elementor-page-4074",
        }}
      />
      <SEO
        title={`Pharmetrics Lab | ${job.title}`}
        description="A Contract Research Organization (CRO) based in Indonesia providing full range of clinical trial and contract services to major companies in ASEAN"
      />
      <div id="perspective">
        <Mobile />
        <div id="wrapper" class="hasbg transparent">
          <div id="elementor-header" class="main-menu-wrapper">
            <div
              data-elementor-type="wp-post"
              data-elementor-id="4287"
              class="elementor elementor-4287"
              data-elementor-settings="[]"
            >
              <div class="elementor-inner">
                <div class="elementor-section-wrap">
                  <Header />

                  <Navbar />
                </div>
              </div>
            </div>
          </div>
          <div id="elementor-sticky-header" className="main-menu-wrapper">
            <div
              data-elementor-type="wp-post"
              data-elementor-id="4287"
              className="elementor elementor-4287"
              data-elementor-settings="[]"
            >
              <div className="elementor-inner">
                <div className="elementor-section-wrap">
                  <Navbar />
                </div>
              </div>
            </div>
          </div>

          <div id="page-content-wrapper" class="wide">
            <div class="inner">
              <div class="inner-wrapper">
                <div class="sidebar-content fullwidth">
                  <div
                    data-elementor-type="wp-page"
                    data-elementor-id="5459"
                    class="elementor elementor-5459"
                    data-elementor-settings="[]"
                  >
                    <div class="elementor-inner">
                      <div class="elementor-section-wrap">
                        <section
                          className="container"
                          style={{ paddingTop: "8rem" }}
                        >
                          <h2>WE'RE HIRING</h2>
                          <h1 style={{ textTransform: "uppercase" }}>
                            {job.title}
                          </h1>
                          <Row className="mt-5">
                            <Col lg={6} sm={12}>
                              <h3>Job Specification</h3>
                              <ul className="mt-0">
                                {job.jobSpecification.map((spec) => (
                                  <li>{spec}</li>
                                ))}
                              </ul>
                            </Col>
                            <Col lg={6} sm={12}>
                              <h3>Job Description</h3>
                              <ul className="mt-0">
                                {job.jobDescriptionTemp.map((desc) => (
                                  <li>{desc}</li>
                                ))}
                              </ul>
                            </Col>
                          </Row>
                          <Row style={{ paddingBottom: "12.5rem" }}>
                            <Col>
                              <h3>Send your CV to:</h3>
                              <h5>{cp.email}</h5>
                              <h5>{cp.phoneNumber}</h5>
                              <h5>or</h5>
                              <h5>
                                {job.link.map((link) => (
                                  <a href={link} target="_blank">
                                    Apply Here
                                  </a>
                                ))}
                              </h5>
                            </Col>
                          </Row>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <JSberhasil />
    </React.Fragment>
  )
}

export default JobApplicationDetail

export const query = graphql`
  query JobDetailQuery($slug: String) {
    contentfulJobs(slug: { eq: $slug }) {
      title
      slug
      jobDescriptionTemp
      jobSpecification
      link
    }
    contentfulContactPerson {
      email
      phoneNumber
    }
  }
`
